<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">宣传教育情况记录</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    prop="HDMC"
                    label="活动名称"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.HDMC"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="HDLX"
                    label="活动类型"
                    class="from-item-block"
                >
                    <el-select
                        id="ycys"
                        v-model="formdata.dataDic.HDLX"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in HDLXList"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="活动情况描述" class="from-item-block">
                    <el-input
                        v-model="formdata.dataDic.HDQKMS"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="组织部门" class="from-item-block">
                    <el-input
                        v-model="formdata.dataDic.ZZBM"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="参与人员" class="from-item-block">
                    <el-input
                        v-model="formdata.dataDic.CYRY"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="HDSJ"
                    label="活动时间"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.HDSJ"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="影响评估" class="from-item-block">
                    <el-select
                        id="ycys"
                        v-model="formdata.dataDic.YXPG"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in YXPGList"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="影响描述" class="from-item-block">
                    <el-input
                        v-model="formdata.dataDic.YXMS"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="文件" class="from-item-block">
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="itemId"
                        :metaData.sync="formdata.metaJson"
                        basicType="document"
                        :disabled="!isEdit"
                        :maxSize="500"
                    ></basic-upload>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "xchdqkjl",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "181",
                dataDic: {
                    HDMC: "", // 活动名称
                    HDLX: "", // 活动类型
                    HDQKMS: "", // 活动情况描述
                    ZZBM: "", // 组织部门
                    CYRY: "", // 参与人员
                    HDSJ: "", // 活动时间
                    YXPG: "", // 影响评估
                    YXMS: "", // 影响描述
                },
            },
            rules: {
                HDMC: [
                    {
                        required: true,
                        message: "请填写活动名称",
                        trigger: "blur",
                    },
                ],
                HDLX: [
                    {
                        required: true,
                        message: "请选择活动类型",
                        trigger: "change",
                    },
                ],
                HDSJ: [
                    {
                        required: true,
                        message: "请填写活动时间",
                        trigger: "blur",
                    },
                ],
            },
            YCSSCDList: [],
            SJLXList: [],
            showQTSJXLX: false,
            YXPGList: [], // 影响评估 枚举值列表
            HDLXList: [], // 活动类型 枚举值列表
        };
    },
    mounted() {
        this.GetEnum();
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID"]),
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "181",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "HDLX") {
                    this.HDLXList = v.dataItems;
                }
                if (v.COLUMNID === "YXPG") {
                    this.YXPGList = v.dataItems;
                }
            });
        },
        GetDataNext() {
            if (this.formdata.dataDic && this.formdata.dataDic.HDLX) {
                this.formdata.dataDic.HDLX = this.formdata.dataDic.HDLX + "";
            }
            if (this.formdata.dataDic && this.formdata.dataDic.YXPG) {
                this.formdata.dataDic.YXPG = this.formdata.dataDic.YXPG + "";
            }
        },
    },
};
</script>

<style></style>
